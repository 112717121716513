import * as React from "react"
import { Context, createContext, FC, useContext, useEffect, useState } from "react"
import { DEFAULT_GIFT_LIST_CONTEXT_TYPE, GiftListContextType } from "../model/GiftListContextType"
import ProductSelectionType from "../pages/gift/choice/ProductSelectionType"
import { doSelectionsMatch } from "../util/productUtil"
import OrderType from "../model/OrderType"
import { LoginContext } from "social-supermarket-components"
import * as orderApi from "../api/platform/orderApi"

export const GiftListContext: Context<GiftListContextType> = createContext<GiftListContextType>({
  ...DEFAULT_GIFT_LIST_CONTEXT_TYPE,
})

interface Props {
  children: React.ReactNode
}

const GiftListProvider: FC<Props> = ({ children }) => {
  const { user, onLogOut, token } = useContext(LoginContext)
  const [isLoadingGiftPages, setIsLoadingGiftPages] = useState<boolean>(true)
  const [choices, setChoices] = useState<ProductSelectionType[]>([])
  const [allGiftLists, setGiftLists] = useState<OrderType[]>([])

  useEffect(() => {
    if (user && token) {
      fetchGiftLists()
    } else {
      setGiftLists([])
    }
  }, [user, token])

  const fetchGiftLists = async () => {
    try {
      setGiftLists([])
      setIsLoadingGiftPages(true)
      const orders = await orderApi.getOrders(token, user.ID)
      setGiftLists(prev => {
        const newPages = [...prev]
        orders.forEach(o => {
          if (!prev.some(p => p.key === o.key)) {
            newPages.push(o)
          }
        })
        return newPages
      })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingGiftPages(false)
    }
  }

  const addChoices = (choicesToAdd: ProductSelectionType[]) => {
    const newChoices = [...choices]
    choicesToAdd.forEach(choiceToAdd => {
      if (!choices.some(c => doSelectionsMatch(c, choiceToAdd))) {
        newChoices.push(choiceToAdd)
      }
    })

    setChoices([...newChoices])
  }

  const updateGiftList = (giftList: OrderType) => {
    setGiftLists(prev => {
      if (!prev) {
        setGiftLists([giftList])
      } else if (prev.some(gl => gl.key === giftList.key)) {
        const newLists = []
        prev.forEach(gl => {
          if (gl.key === giftList.key) {
            newLists.push(giftList)
          } else {
            newLists.push(gl)
          }
        })
        return newLists
      } else {
        return [...prev, giftList]
      }
    })
  }

  const removeChoice = (choice: ProductSelectionType) => {
    const newChoices = [...choices].filter(c => !doSelectionsMatch(c, choice))
    setChoices(newChoices)
  }

  const cancel = () => {
    setChoices([])
  }

  return (
    <GiftListContext.Provider
      value={{
        choices,
        addChoices,
        removeChoice,
        cancel,
        allGiftLists,
        updateGiftList,
        isLoadingGiftPages,
      }}
    >
      {children}
    </GiftListContext.Provider>
  )
}

export default GiftListProvider
