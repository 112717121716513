import * as React from "react"
import { Context, createContext, FC, useContext, useEffect, useState } from "react"
import * as favoritesApi from "../api/favoritesApi"
import { DEFAULT_FAVORITES_CONTEXT_TYPE, FavoritesContextType } from "../model/FavoritesContextType"
import { LoginContext } from "social-supermarket-components"
import FavoriteType from "../model/FavoriteType"
import { ProductType } from "social-supermarket-model"

export const FavoritesContext: Context<FavoritesContextType> = createContext<FavoritesContextType>({
  ...DEFAULT_FAVORITES_CONTEXT_TYPE,
})

interface Props {
  children: React.ReactNode
}

const FavoritesProvider: FC<Props> = ({ children }) => {
  const [favoriteProducts, setFavorites] = useState<FavoriteType[]>([])
  const { token, initialising, user } = useContext(LoginContext)

  useEffect(() => {
    if (token && user) {
      fetchFavorites()
    }
  }, [token, user])

  const fetchFavorites = async () => {
    try {
      setFavorites([])
      const favorites = await favoritesApi.getFavorites(token, user.ID)
      setFavorites(favorites)
    } catch (e) {
      // console.log(`Unable to fetch favorites, please contact support: ${e}`)
    }
  }

  const toggleFavorite = async (product: ProductType, category: string) => {
    const isFavorite = favoriteProducts.some(favorite => favorite.product.slug === product.slug)
    if (isFavorite) {
      removeFavorite(product, category)
    } else {
      addFavorite(product, category)
    }
  }

  const addFavorite = async (product: ProductType, category: string) => {
    try {
      const newFavorites = await favoritesApi.addFavorite(
        product.productId,
        category,
        token,
        user.ID
      )
      setFavorites(newFavorites)
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    }
  }

  const removeFavorite = async (product: ProductType, category: string) => {
    try {
      const newFavorites = await favoritesApi.removeFavorite(
        product.productId,
        category,
        token,
        user.ID
      )
      setFavorites(newFavorites)
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    }
  }

  return (
    <FavoritesContext.Provider
      value={{
        toggleFavorite,
        favoriteProducts,
        addFavorite,
        removeFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  )
}

export default FavoritesProvider
