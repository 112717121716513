import SwiperCore, { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper"
import "swiper/swiper-bundle.min.css"

// admin
export { default as UserSelector } from "./components/admin/UserSelector"

// Buttons
export { default as Buttons } from "./components/button/Buttons"
export { default as FullLinkButton } from "./components/button/FullLinkButton"
export { default as LinkButton } from "./components/button/LinkButton"
export { default as PrimaryButton } from "./components/button/PrimaryButton"

// checkout
export { default as CheckoutVendors } from "./components/checkout/CheckoutVendors"
export { default as CheckoutVendorItems } from "./components/checkout/CheckoutVendorItems"
export { default as CheckoutVendorDeliveryDate } from "./components/checkout/CheckoutVendorDeliveryDate"
export { default as SidebarBasketItem } from "./components/checkout/SidebarBasketItem"

// form
export { default as CompanyField } from "./components/form/fields/CompanyField"
export { default as CompanySizeField } from "./components/form/fields/CompanySizeField"
export { default as EmailField } from "./components/form/fields/EmailField"
export { default as FirstNameField } from "./components/form/fields/FirstNameField"
export { default as FullNameField } from "./components/form/fields/FullNameField"
export { default as HearAboutUsField } from "./components/form/fields/HearAboutUsField"
export { default as LastNameField } from "./components/form/fields/LastNameField"
export { default as PhoneField } from "./components/form/fields/PhoneField"

export { default as AddressForm } from "./components/form/forms/address/AddressForm"
export { default as ContactDetailsForm } from "./components/form/forms/contact-details/ContactDetailsForm"
export { DEFAULT_CONTACT_DETAILS_STATE } from "./components/form/forms/contact-details/ContactDetailsForm"
export { default as MetaForm } from "./components/form/forms/meta/MetaForm"
export { default as YourDetailsForm } from "./components/form/forms/your-details/YourDetailsForm"
export { DEFAULT_YOUR_DETAILS_STATE } from "./components/form/forms/your-details/YourDetailsForm"
export type { YourDetailsFormType } from "./components/form/forms/your-details/YourDetailsForm"
export { default as LoginForm } from "./components/form/forms/login/LoginForm"
export { DEFAULT_LOGIN_FORM_STATE } from "./components/form/forms/login/LoginForm"
export type { LoginFormType } from "./components/form/forms/login/LoginForm"
export { default as RegisterForm } from "./components/form/forms/register/RegisterForm"
export { default as PasswordResetForm } from "./components/form/forms/password-reset/PasswordResetForm"
export { DEFAULT_PASSWORD_RESET_FORM_STATE } from "./components/form/forms/password-reset/PasswordResetForm"
export type { PasswordResetFormType } from "./components/form/forms/password-reset/PasswordResetForm"
export { default as LoginFormWrapper } from "./components/form/forms/login/LoginFormWrapper"
export { default as PunchOutLoginForm } from "./components/form/forms/punch-out-login/PunchOutLoginForm"
export { default as PunchOutLoginFormWrapper } from "./components/form/forms/punch-out-login/PunchOutLoginFormWrapper"
export { default as PasswordResetFormWrapper } from "./components/form/forms/password-reset/PasswordResetFormWrapper"
export { default as RegisterFormWrapper } from "./components/form/forms/register/RegisterFormWrapper"
export { default as UpdatePasswordFormWrapper } from "./components/form/forms/update-password/UpdatePasswordFormWrapper"
export { default as UpdatePasswordForm } from "./components/form/forms/update-password/UpdatePasswordForm"
export { DEFAULT_UPDATE_PASSWORD_FORM_STATE } from "./components/form/forms/update-password/UpdatePasswordForm"
export type { UpdatePasswordFormStateType } from "./components/form/forms/update-password/UpdatePasswordForm"
export { default as PunchOutRegisterForm } from "./components/form/forms/punch-out-register/PunchOutRegisterForm"
export { default as PunchOutRegisterFormWrapper } from "./components/form/forms/punch-out-register/PunchOutRegisterFormWrapper"
export { default as RequestQuoteForm } from "./components/form/forms/lead-capture/RequestQuoteForm"
export { DEFAULT_REQUEST_QUOTE_FORM_STATE } from "./components/form/forms/lead-capture/RequestQuoteForm"
export type { RequestQuoteFormStateType } from "./components/form/forms/lead-capture/RequestQuoteForm"
export { default as RequestQuoteFormModal } from "./components/form/forms/lead-capture/RequestQuoteFormModal"
export { default as RequestQuoteFormWrapper } from "./components/form/forms/lead-capture/RequestQuoteFormWrapper"
export { default as TopAlignedSearchInput } from "./components/form/inputs/top-aligned-inputs/TopAlignedSearchInput"
export { default as TopAlignedTextarea } from "./components/form/inputs/top-aligned-inputs/TopAlignedTextarea"
export { default as TopAlignedTextareaField } from "./components/form/inputs/top-aligned-inputs/TopAlignedTextareaField"
export { default as TopAlignedTextInput } from "./components/form/inputs/top-aligned-inputs/TopAlignedTextInput"
export { default as TopAlignedTextInputField } from "./components/form/inputs/top-aligned-inputs/TopAlignedTextInputField"
export { default as OutlinedInputField } from "./components/form/inputs/outlined-inputs/OutlinedInputField"
export { default as OutlinedTextAreaField } from "./components/form/inputs/outlined-inputs/OutlinedTextAreaField"
export { default as BasicNumberInput } from "./components/form/inputs/basic-inputs/BasicNumberInput"
export { default as BasicTextInput } from "./components/form/inputs/basic-inputs/BasicTextInput"
export { default as Checkbox } from "./components/form/inputs/basic-inputs/Checkbox"

// model
export type { ControlType } from "./model/ControlType"

export type { default as ComponentContextType } from "./model/ComponentContextType"
export type { OrderContextType } from "./model/OrderContextType"

export { DEFAULT_ORDER_CONTEXT_TYPE } from "./model/OrderContextType"

// images
export { default as ProductImageGallery } from "./components/images/gallery/ProductImageGallery"
export { default as SquareImage } from "./components/images/SquareImage"
export { default as UrlImage } from "./components/images/UrlImage"

// layout
export { default as Card } from "./components/layout/Card"
export { default as ProgressIndicator } from "./components/layout/ProgressIndicator"

// menu
export { default as MoreMenu } from "./components/menu/MoreMenu"

// providers
export { default as ComponentProvider, ComponentContext } from "./context/ComponentProvider"
export { default as ThemeProvider, ThemeContext } from "./context/ThemeProvider"
export { default as LoginProvider, LoginContext } from "./context/LoginProvider"
export { default as OrderProvider } from "./context/OrderProvider"

// modal
export { default as Modal } from "./components/modal/Modal"
export { default as ModalFooter } from "./components/modal/ModalFooter"
export { default as Portal } from "./components/modal/Portal"
export { default as SideModal } from "./components/modal/SideModal"

// product
export { default as ProductCard } from "./components/product/ProductCard"
export { default as ProductStockIndicator } from "./components/product/ProductStockIndicator"
export { default as ProductAttributeField } from "./components/product/form/ProductAttributeField"
export {
  default as ProductAttributeForm,
  DEFAULT_ATTRIBUTE_STATE,
} from "./components/product/form/ProductAttributeForm"
export { default as ProductDetailsTabs } from "./components/product/ProductDetailsTabs"
export { default as ProductModal } from "./components/product/ProductModal"
export { default as ProductModalBody } from "./components/product/ProductModalBody"
export { default as ProductQuantityInput } from "./components/product/ProductQuantityInput"
export { default as VolumeDiscounts } from "./components/product/VolumeDiscounts"

// spinner
export { default as Spinner } from "./components/spinner/Spinner"
export { default as SpinnerIcon } from "./components/spinner/SpinnerIcon"

// svg
export { default as CloseIcon } from "./components/svg/CloseIcon"
export { default as IconWrapper } from "./components/svg/IconWrapper"

// tabs
export { default as SideTabs } from "./components/tabs/SideTabs"
export { default as Tab } from "./components/tabs/Tab"
export { default as Tabs } from "./components/tabs/Tabs"

export * from "./hooks/useQueryString"

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y])
