import * as React from "react"
import { getConfig } from "../../config/index"
import { Context, createContext, FC } from "react"
import {
  DEFAULT_ORDER_CONTEXT_TYPE,
  OrderContextType,
  OrderProvider,
} from "social-supermarket-components"

export const SuppliesOrderContext: Context<OrderContextType> = createContext<OrderContextType>({
  ...DEFAULT_ORDER_CONTEXT_TYPE,
})

interface Props {
  children: React.ReactNode
}

const SuppliesOrderProvider: FC<Props> = ({ children }) => {
  return (
    <OrderProvider
      baseUrl={getConfig().baseUrl}
      category={"SUPPLIES"}
      Context={SuppliesOrderContext}
      children={children}
      usesGiftNotes={false}
    />
  )
}

export default SuppliesOrderProvider
